.admin-progress {
    position: relative;
    background-color: #4e4e4e;
    border-radius: 20px;
    height: 20px;
    width: 100%;
}

.admin-progress-bar {
    background-color: #007bff;
    border-radius: 20px;
    height: 100%;
}

.admin-progress-status {
    position: absolute;
    font-size: 12px;
    color: #fff;
    margin-left: 10px;
    overflow: hidden;
}