
.FormInput-select-list {
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    right: 0;
    width: 100%;
    max-height: 500px;
    padding-bottom: 42px;
}

.FormInput-select-list-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.FormInput-select-list .list-group {
    overflow-y: auto;
}

/* Small Screen & Mobile */
@media (max-width: 991.98px) {
    .FormInput-select-list {
        position: fixed;
        left: 0;
        bottom: 0;
        top: 50px;
        right: 0;
        z-index: 9999;
        background-color: white;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 100%;
        height: auto;
        max-height: none;
    }

}