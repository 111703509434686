
.info-badge {
    position: absolute;
    padding: 0 0.3rem;
    font-size: 1rem;
    opacity: 0.2;
}

.info-badge:hover {
    opacity: 1;
}