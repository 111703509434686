
.ai-loader-container {
    position: relative;
    display: block;
    width: 100%;
    height: 170px;
    text-align: center;
}

.ai-loader-container p {
    padding-top: 130px;
}

.ai-loader {
    position: absolute;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
    left: 50%;
    margin-left: -50px;
    aspect-ratio: 1;
    display: grid;
    border-radius: 50%;
    background:
        linear-gradient(0deg, rgb(0 0 0/50%) 30%, #0000 0 70%, rgb(0 0 0/100%) 0) 50%/8% 100%,
        linear-gradient(90deg, rgb(0 0 0/25%) 30%, #0000 0 70%, rgb(0 0 0/75%) 0) 50%/100% 8%;
    background-repeat: no-repeat;
    animation: l23 1s infinite steps(12);
}

.ai-loader::before,
.ai-loader::after {
    content: "";
    grid-area: 1/1;
    border-radius: 50%;
    background: inherit;
    opacity: 0.915;
    transform: rotate(30deg);
}

.ai-loader::after {
    opacity: 0.83;
    transform: rotate(60deg);
}

@keyframes l23 {
    100% {
        transform: rotate(1turn)
    }
}