
:root {
    --c-heading-color: #50be4d;
    --c-bg-shadow-color: #e8f3e8;
    --c-light-text-color: rgb(255,255,255);
    --c-bg-color: #f0f0f0;
    --c-bg-color-light: #ffffff;
    --c-border-color: rgb(228, 234, 240);
    --c-headers-font: Rubik, sans-serif;
    --c-transition: 0.3s ease-in-out;
    --c-sidebar-width: 80px;
    --c-editor-top-offset: 0px;
    --c-sidebar-expand-width: 200px;
    --c-a-color: #4278c4;
    --c-a-active-bg-color: #deecff;
    --c-link-color: rgb(65, 77, 85);
    --c-link-hover-color: rgb(38, 98, 240);
    --c-link-active-text-color: rgb(241, 245, 248);
    --c-link-active-bg-color: rgb(103, 114, 122);
    --c-faded-text-color: rgb(65, 77, 85, 0.65);
    --c-nav-tabs-link-active-bg: #fff;
    --c-nav-tabs-border-color: #dee2e6;
    --c-table-head-color: #e9ecef;
    --c-highlight: #fffdb3;
    --c-bg-alt-color: rgb(245, 247, 247);
    --c-bg-light-alt: #e3e3e3;
    --c-bg-info-light: #e0edff;
    --c-bg-warning-light: #ffe2ba;
    --c-no-color: rgba(0,0,0,0);
    --c-box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    --c-wbp-hover-border: 2px dashed var(--c-a-color);
    --c-active-border-color: #4723d9;
    --c-header-color: rgba(255,0,0,0.15);
    --c-body-color: rgba(0,0,0,0.15);
    --c-footer-color: rgba(0,0,255,0.15);
}

html body {
    font-family: Montserrat, sans-serif;
    font-size: 1rem;
    color: #000;
    background-color: var(--c-bg-color);
}

html a {
    color: var(--c-a-color);
    text-decoration: none;
}

.bolder {
    font-weight: bolder;
}

.bg-light-alt {
    background-color: var(--c-bg-light-alt);
}

.bg-info-light {
    background-color: var(--c-bg-info-light);
}

.bg-warning-light {
    background-color: var(--c-bg-warning-light);
}

.drop-shadow {
    box-shadow: var(--c-box-shadow);
}

.text-shadow {
    text-shadow: #000 1px 0 10px;
}

.bg-info-light input {
    border: 0;
}

.color-primary {
    color: var(--c-heading-color);
}

.c-login {
    height: 100vh;
    background-color: #eee;
}

.c-plans .card {
    min-height: 400px;
    box-shadow: var(--c-box-shadow);
}

main > .container > .card {
    border: none;
}

.stick-bottom {
    position: absolute;
    bottom: 0px;
    padding: 10px;
    width: 100%;
    text-align: center;
}

.mh-100vh {
    max-height: 100vh;
}

.p20-mh80 {
    padding: 5vh;
    max-height: 90vh;
}

.offcanvas {
    background-color: #4f3f41 !important;
}

.offcanvas-header{
    color: #000;
}

.btn-close-offcanvas {
    color:#000 !important;
    background-color: transparent;
    border: none;
}

.offcanvas {
    background-color: #eee !important;
    color: #000;
}

.offcanvas-bottom {
    margin-left: 64px;
    border: 1px solid #227c90;
}


.c-sidebar {
    width: var(--c-sidebar-width);
    float: left;
    height: calc(100vh - 56px);
    left: 0;
    top: 56px;
    position: fixed;
    z-index: 999;
}

.c-sidebar-open {
    display: block;
}

.c-sidebar-close {
    display: none;
}

.c-sidebar a {
    color: #000;
}

    .c-sidebar a.active {
        color: #999;
    }

.c-sidebar .border-light {
    border-color: #999 !important;
}

.c-sidebar .nav-pills .nav-link {
    border-radius: 0;
}

.c-sidebar .nav-link svg {
    width: 20px;
}

.c-sidebar .dropdown button, .c-sidebar .dropdown-menu {
    width: 100%;
}

.c-sidebar .dropdown-toggle::after {
    position: absolute;
    right: 15px;
}

.pointer {
    cursor: pointer;
}

.c-formlabel h5 {
    color: var(--c-faded-text-color);
    border-bottom: 1px solid var(--c-nav-tabs-border-color);
}

.c-formlabel div.c-value {
    padding-top: 5px;
}

a {
    text-decoration: none;
    color: var(--bs-primary);
}

.c-com {
    border: 1px solid var(--c-no-color);
    padding: 2px;
    cursor: move;
    min-width: 70%;
}

    .c-com:hover {
        border: 1px solid var(--c-a-color);
    }

.navbar.c-topbar {
    background-color: var(--c-bg-color-light);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.c-topbar .dropdown-toggle::after {
    display: none;
}

.c-topbar .dropdown-menu {
    width: 100%;
    margin-top: 15px;
}

.c-user {
    font-size: 24px;
    font-family: Roboto;
}

    .c-user strong {
        display: inline-block;
        margin-right: 10px;
    }

.c-usericon {
    background-color: var(--c-bg-shadow-color);
    color: var(--c-heading-color);
    padding: 15px;
    font-size: 24px;
    font-weight: bolder;
    min-width: 40px;
    min-height: 40px;
    border-radius: 5px;
}

html h1.h {
    font-size: 38px;
    letter-spacing: 2px;
    font-weight: bold;
    color: var(--c-heading-color);
    padding: 15px;
    text-align: center;
}

html h2.sub {
    font-size: 24px;
}

html h5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

html .c-template-thumb {
    max-height: 230px;
    width: 100%;
}


.header {
    background-color: #4f3f41;
}

.card {
    background-color: #fff;
    box-shadow: 0px 0px 5px #eee;
}

html .nav-tabs {
    margin-bottom: 10px;
}

.c-searchlist li:hover {
    background-color: var(--c-highlight);
}

.c-hoverable .c-visible-onhover {
    display: none;
}

.c-hoverable:hover .c-visible-onhover {
    display: inline-block;
}


.wbp-toolbar {
    z-index: 999;
    position: absolute;
    margin-top: 0px;
/*
    width: calc(100% - 20px);
    */
    min-width: 600px;
    max-width: calc(100vw - 80px);
}

.wbp-col-head {
    display: none;
    position: absolute;
    align-content: end;
    justify-content: end;
    color: var(--c-light-text-color);
    width: 100%;
    top: 0px;
    right: 12px;
    height: 30px;
}

    .wbp-col-head.sel {
        display: flex;
    }

    .wbp-col-head .name {
        background-color: var(--c-active-border-color);
        padding: 3px;
        font-size: 14px;
        border-radius: 0 0 3px 3px;
        z-index: 999;
    }

.wbp-col .wbp-toolbar .btn-light, .wbp-placeholder-col .wbp-toolbar .btn-light  {
    background-color: var(--c-active-border-color);
    padding: 5px;
    font-size: 14px;
    border-radius: 0 0 3px 3px;
    border: 0;
    color: var(--c-light-text-color);
}

.wbp-toolbar .btn-group {
    margin-right: 5px;
    box-shadow: var(--c-box-shadow);
}

.wbp-editor-wrapper, .wbp-editor-header, .wbp-editor-footer {
    position: relative;
    min-height: 20px;
    /*min-height: calc(100vh - 65px);*/
}



.wbp-editor-wrapper .container, .wbp-editor-wrapper .container-fluid, .wbp-editor-wrapper .navbar { 
    min-height: 30px;
}


.wbp-editor-content-data.hover {
    border: 1px solid var(--c-a-color);
}

.wbp-editor-active .wbp-editor-content-data.hover {
    border: none;
}

.wbp-main-toolbar {
    position: fixed;
    top: 100px;
    left: var(--c-sidebar-width);
    width: calc(100% - var(--c-sidebar-width));
    z-index: 995;
    border-bottom: 1px solid var(--c-border-color);
}

.wbp-editor-active > .wbp-editor-content, .wbp-placeholder-col.wbp-editor-active > .wbp-editor-content {
    border: 3px solid var(--c-active-border-color);
}

.wbp-editor-content .wbp-editor-content .wbp-editor-active {
    border: 0;
}

    .wbp-container {
        min-height: 50px;
    }

    .wbp-container.wbp-move-hover {
        border: var(--c-wbp-hover-border);
    }

.wbp-col {
    position: relative;
}

.wbp-placeholder-col {
    position: relative;
}

.wbp-placeholder-col .wbp-editor-content {
    border: 1px dashed rgba(0,0,0,0.3);
    padding: 50px;
}

.wbp-placeholder-col.wbp-move-hover {
    border: var(--c-wbp-hover-border);
}


.wbp-move-floater {
    position: absolute;
    color: var(--c-a-color);
    border: var(--c-wbp-hover-border);
    padding: 15px;
    margin: 5px;
    z-index: 50;
    background-color: var(--c-a-active-bg-color);
    border-radius: 1rem;
}

    .wbp-col.wbp-move-hover {
        margin-left: 120px;
    }

        .wbp-col.wbp-move-hover::before {
            content: 'Insert Here';
            color: var(--c-a-color);
            border: var(--c-wbp-hover-border);
            padding: 15px;
            margin: 5px;
            display: block;
            float: left;
            flex: 1 0;
            margin-left: -120px;
        }

    .wbp-col.wbp-move-hover-after {
        margin-right: 120px;
    }

        .wbp-col.wbp-move-hover-after::after {
            content: 'Insert Here';
            color: var(--c-a-color);
            border: var(--c-wbp-hover-border);
            padding: 15px;
            margin: 5px;
            display: block;
            float: right;
            flex: 1 0;
            margin-right: -120px;
            margin-top: -70px;
        }

/*
.wbp-col.wbp-move-hover {
    border-left: var(--c-wbp-hover-border);
}
*/

.move {
    cursor: move;
    user-select: none
    /*
    background: repeating-linear-gradient( 45deg, #ffffff, #ffffff 10px, #dddddd 10px, #dddddd 20px );
        */
}

.c-template-hover {
    position: absolute;
    background-color: rgba(255,255,255,0.5);
    display: flex;
    width: 100%;
    align-items: center;
}

.c-pagewrapper {
    margin-top: 65px;
}

.c-sidebar {
    display: block !important;
    height: calc(100vh - 100px);
    top: 100px;
    position: fixed;
}

.c-sidebar-expand {
    height: calc(100vh - 50px);
    top: 100px;
    left: 50px;
    width: var(--c-sidebar-expand-width);
    position: fixed;
}

.c-sidebar-expand-content {
    padding-left: 15px;
    padding-right: 15px;
}

.c-com-list {
    max-height: calc(100vh - 105px);
    overflow-y: scroll;
}

.c-com-list .icon {
    text-align: center;
    font-size: 25px;
}


.c-sidebar-menu {
    border-top: 1px solid #dddddd;
    width: 100%;
    border-right: 1px solid var(--c-border-color);
    height: calc(100vh - 164px);
}

.c-sidebar-item {
    font-size: 25px;
    color: #444444;
    margin: 7px;
    text-align: center;
}

.c-sidebar-item.active {
    color: var(--c-a-color);
    background-color: var(--c-a-active-bg-color);
    border-radius: 100%;
}

.wbp-editor {
    margin-left: var(--c-sidebar-width);
    width: calc(100% - var(--c-sidebar-width));
    margin-top: var(--c-editor-top-offset);
}

.wbp-hint-append {
    display: none;
    text-align: center;
    padding: 15px;
    border: var(--c-wbp-hover-border);
    color: var(--c-a-color);
}

.wbp-editor-header {
    border-bottom: 1px dashed var(--c-header-color);
}

.wbp-editor-footer {
    border-top: 1px dashed var(--c-footer-color);
}

.wbp-editor-header.wbp-move-hover {
    background-color: rgba(255,0,0,0.1);
}

.wbp-editor-footer.wbp-move-hover {
    background-color: rgba(0,0,255,0.1);
}

.wbp-editor-wrapper.wbp-move-hover > .wbp-hint-append, .wbp-editor-header.wbp-move-hover > .wbp-hint-append, .wbp-editor-footer.wbp-move-hover > .wbp-hint-append {
    display: block;
}

.accordion {
    padding: 5px;
}

.wbp-placeholder {
    text-align: center;
    padding: 15px;
}

.wbp-header-color {
    color: var(--c-header-color);
}

.wbp-body-color {
    color: var(--c-body-color);
}

.wbp-footer-color {
    color: var(--c-footer-color);
}

.app-form-buttons {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background-color: var(--bs-white);
    box-shadow: 5px -5px 5px rgba(0,0,0,0.1);
    z-index: 999;
}


.staging {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 226, 186, 0.5);
    padding: 8px;
    text-align: center;
    z-index: 999;
    box-shadow: 0 5px 5px rgba(0,0,0,0.1);
}

@media (min-width: 768px) {
    .c-nav {
        display: none !important;
    }

    .app-form-buttons {
        left: var(--c-sidebar-width);
        margin-left: var(--c-sidebar-expand-width);
    }


    .c-sidebar-close {
        display: block !important;
    }

        .c-sidebar-close.force {
            display: none !important;
        }


    .c-pagewrapper h1:first-of-type {
        margin-left: -12px;
        padding-left: 12px;
    }

    .c-list-position-md-absolute {
        position: absolute;
        z-index: 999;
        margin-top: 50px;
    }
}
