.modal-style {
    background-color: #fedab300;
    border: none;
}

html .custom-card3 {
    width: 50%;
    position: relative;
    border-radius: 9px;
    background-color: #fff;
    overflow: hidden;
}

.custom-card3 .card-buttons {
    position: absolute;
    left: 15px;
    bottom: 15px;
}

.custom-card3 .card-buttons button, .custom-card3 .card-buttons a {
    color: #fff !important;
    font-weight: 600;
    text-shadow: rgba(0,0,0,0.5) 0px 1px 3px;
}


.fa-times-circle {
    font-size: 20px;
    color: #424242;
}

.welcome-title {
    font-weight: 800;
}

.modal-header-weight {
    font-weight: 700;
}

.welcome-card-text {
    font-size: 13px;
}

.welcome-img-1 {
    margin: 75px 0px -53px 0px;
}

.welcome-img-2 {
    margin: 0px 0px -2px -25px;
    width: 200px;
}

.welcome-img-3 {
    margin: 0px 0px -43px -5px;
}

.welcome-img-4 {
    margin: -45px 0px -53px -25px;
}

.template-modal-card-title {
    font-weight: 700;
}

.template-modal-text {
    font-size: 14px;
}

.use-template-img {
    margin: -15px 0px -50px -25px;
}

.use-ai-img {
    max-width: 235px;
    margin: -5px 0px -44px -25px;
}

.get-started-ai {
    color: #000;
    padding: 10px 40px 10px 40px;
}

.final-title-text {
    font-weight: 600;
    color: #50be4d;
}

.line-height-adjust {
    line-height: 1.7em;
}

.tap-text-color {
    color: #50be4d;
}

.LauncherModal h1 {
    color: #fff;
}